<template>
    <div id="Layer-warp">
        <el-container>
            <el-header height="90px" :style="style">
                <div class="header-main-all">
                    <router-link to="home">
                        <el-image class="header-main-image" :src="require('@/assets/image/logo.png')"></el-image>
                    </router-link>
                    <div class="header-main-left">
                        <el-menu router :default-active="activeIndex" class="el-menu-demo" mode="horizontal" >
                            <el-menu-item :style="itemStyle" index="/guidance">转运介绍</el-menu-item>
                            <el-menu-item :style="itemStyle" index="/home">代理产品</el-menu-item>
                            <el-menu-item :style="itemStyle" index="/buying">海淘下单</el-menu-item>
                            <el-menu-item :style="itemStyle" index="/order">我的订单</el-menu-item>
                            <el-menu-item :style="itemStyle" index="/taobao">禁运产品</el-menu-item>
                            <el-menu-item :style="itemStyle" index="/about">关于我们</el-menu-item>
                            <el-submenu index="/my">
                                <template slot="title">
                                    <el-image class="header-main-my" :src="require('@/assets/image/my.png')"></el-image>    
                                </template>
                                <el-menu-item style="background-color:#ffffff" index="/my">个人中心</el-menu-item>
                                <el-menu-item style="background-color:#ffffff" index="/login">注册/登录</el-menu-item>
                                <el-menu-item @click="outClick">退出登录</el-menu-item>
                            </el-submenu>
                            <div v-if="loginStatus" class="heaer-main-vip-div">
                                <el-image class="header-main-vip" :src="require('@/assets/image/vip.png')"></el-image>
                            </div>
                            
                        </el-menu>
                    </div>
                </div>
            </el-header>
            <el-main>
                <router-view></router-view>
            </el-main>
            <el-footer height="350px" id="all-footer">
                <div class="footer-main-all">
                    <el-image class="footer-qr" :src="footerimg "></el-image>
                    <div>
                        <div class="footer-title">海空百渡 airseas cargo</div>
                        <div class="footer-phone">公司电话：{{phone_gs}}</div>
                        <div class="header-main-left">
                            <div>公司地址：{{address_gs}}</div>
                            <div class="footer-banquan">{{copyright}}</div>
                        </div>
                    </div>
                </div>
            </el-footer>
        </el-container>

    </div>
</template>
<script>
import {getset} from '@/api/index'
export default {
    name:'Layer',
    data(){
        return{
            footerimg:"",
            phone_gs:'',
            activeIndex:this.$route.path,
            address_gs:'',
            copyright:'',
            style: {},
            opacity: 0,
            itemStyle:{},
            loginStatus:false
        }
    },
    mounted() {
        window.addEventListener("scroll", this.windowScroll); //监听页面滚动
    },
    created(){
        let is_vip = JSON.parse(sessionStorage.getItem('is_vip'))

        if(is_vip){
           this.loginStatus = true
        }else{
            this.loginStatus = false
        }
        getset({name:'address_gs'}).then(res=>{this.address_gs = res.data})
        getset({name:'phone_gs'}).then(res=>{this.phone_gs = res.data})
        getset({name:'copyright'}).then(res=>{this.copyright = res.data})
        getset({name:'qr_code'}).then(res=>{this.footerimg = this.$store.state.baseUrl + res.data})
    },
    methods:{
        windowScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            this.opacity = Math.abs(Math.round(scrollTop)) / 250;
            this.style = {background: `rgba(255, 255, 255,${this.opacity})`}
            this.itemStyle = this.opacity < 1 ?{color: `rgba(255, 255, 255)`}:{color: `rgba(0, 0, 0,${this.opacity})`};
            
        },

        // 退出登录
        outClick(){
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('is_vip')
            this.$router.replace('/login')
        },
    }
}
</script>


<style>
.el-main{
    padding: 0 ;
}
.el-header{
    position: fixed;
    width: 100%;
    z-index: 999;
    padding: 0;
    background: transparent;
}
.el-submenu__title:hover{
    background: transparent !important;
}
.el-menu-item:focus, .el-menu-item:hover{
    /* color: #fff !important; */
    background: transparent !important;
    border-bottom:4px solid #f7b832 !important;
}
.el-menu--horizontal>.el-menu-item.is-active{
    background:#f7b832 !important;
    border-bottom:4px solid #f7b832 !important;
    border-radius: 10px;
}
.el-menu--horizontal>.el-menu-item{
    /* margin: 0 20px; */
    font-size: 30px !important;
    color:#FFFFFF ;
}
.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border-bottom:none !important;
}
.el-menu--horizontal{
    background: #f7b832;
}
.el-menu--popup{
    padding: 0px !important;
}
.el-menu--popup-bottom-start{
    margin-top: 0px !important;
}
/* .el-menu--horizontal>.el-menu-item{
    height: 100%;
    display: flex;
    align-items: center;
} */
.el-menu.el-menu--horizontal{
    border-bottom:none
}
.el-menu{
    background-color: transparent;
}
/* .el-header:hover{
    background: #FFFFFF !important;
    color: #000 !important;
} */
</style>

<style scoped>
#all-footer{
    background: #474747;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.el-menu-demo{
    height: 100%;
}
.header-main-all{
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.header-main-image{
    height: 120px;
    width: 320px;
    /* opacity: 0.8; */
}
.footer-qr{
    width: 110px;
    margin-right: 35px;
}
.footer-main-all{
    display: flex;
    align-items: center;
    width: 70%;
    color: #FFFFFF;
    font-size: 20px;
}
.header-main-my{
    height: 44px;
    width: 44px;
}
.header-main-left{
    display: flex;
    align-items: center;
}
.footer-title{
    font-size: 22px;
    margin-bottom: 25px;
}
.footer-phone{
    margin-bottom: 20px;
}
.footer-banquan{
    margin-left: 30px;
}
.title-image{
    height: 100%;
}
.heaer-main-vip-div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-main-vip{
    width: 80px;
    height: 80px;
}
</style>